const LovService = () => {
  const fetchBuildingTypes = () => {
    return [
      { id: "O", value: "Office" },
      { id: "R", value: "Retail" },
      { id: "I", value: "Industrial" },
      { id: "E", value: "Residential" },
      { id: "L", value: "Land" }
    ];
  };

  const fetchSuiteTypes = () => {
    return [
      { id: "I", value: "Industrial" },
      { id: "O", value: "Office" },
      { id: "E", value: "Residential" },
      { id: "R", value: "Retail" },
      { id: "S", value: "Retirement" },
      { id: "Z", value: "Other Leasable" },
      { id: "L", value: "Land" }
    ];
  };

  const fetchSuiteAvailabilityTypes = () => {
    return [
      { id: "P", value: "Public" },
      { id: "PR", value: "Private" },
      { id: "L", value: "Leased" },
      { id: "C", value: "Committed" },
      { id: "M", value: "Month-To-Month" },
      { id: "PE", value: "Pending" }
    ];
  };

  const fetchPubStatuses = () => {
    return [
      { id: "P", value: "Published" },
      { id: "NP", value: "Not Published" }
    ];
  };

  const fetchFavStatuses = () => {
    return [
      { id: "F", value: "Favourites" },
      { id: "NF", value: "Non Favourites" }
    ];
  };

  const fetchArchivedStatuses = () => {
    return [
      { id: "A", value: "Archived" },
      { id: "NA", value: "Not Archived" }
    ];
  };

  const fetchListingStatuses = () => {
    return [
      { id: "L", value: "Listed" },
      { id: "C", value: "Call For Availability" },
      { id: "F", value: "Fully Leased" }
    ];
  };

  const fetchResidentialSuiteTypes = () => {
    return [
      { id: "B", value: "Bachelor" },
      { id: "1", value: "One Bedroom" },
      { id: "2", value: "Two Bedroom" },
      { id: "3", value: "Three Bedroom" },
      { id: "4", value: "Four Bedroom" },
      { id: "5", value: "Five Bedroom+" }
      //{ id : "T", value : "Townhouse" },
    ];
  };

  const fetchSuiteConditionTypes = () => {
    return [
      { id: "F", value: "Fully Built Out" },
      { id: "7", value: "75 % Complete" },
      { id: "5", value: "50 % Complete" },
      { id: "1", value: "10 % Complete" },
      { id: "B", value: "Base Building" }
    ];
  };

  const fetchResidentialBuildingTypes = () => {
    return [
      { id: "C", value: "Condominium" },
      { id: "L", value: "Lowrise" },
      { id: "M", value: "Midrise" },
      { id: "H", value: "Highrise" },
      { id: "T", value: "Townhouse" },
      { id: "B", value: "Mobile" }
    ];
  };

  const fetchUserTypes = () => {
    return [
      { id: "AA", value: "Arcestra Administrator" },
      { id: "CA", value: "Company Administrator" },
      { id: "LA", value: "Leasing Administrator" },
      { id: "LB", value: "Leasing Broker" }
    ];
  };

  const fetchExteriorWallTypes = () => {
    return [
      { id: "B", value: "Brick" },
      { id: "S", value: "Stone" },
      { id: "M", value: "Metal" },
      { id: "G", value: "Glass" },
      { id: "T", value: "Stucco" },
      { id: "P", value: "Pre-Cast Slab Concrete" },
      { id: "O", value: "Other" }
    ];
  };

  const fetchRoofingTypes = () => {
    return [
      { id: "M", value: "Metal" },
      { id: "B", value: "Membrane" },
      { id: "T", value: "Tile" },
      { id: "C", value: "Concrete" },
      { id: "O", value: "Other" }
    ];
  };

  const fetchBOMATypes = () => {
    return [
      { id: "P", value: "Platinum" },
      { id: "G", value: "Gold" },
      { id: "S", value: "Silver" },
      { id: "B", value: "Bronze" },
      { id: "C", value: "Certified" }
    ];
  };

  const fetchBOMASUSTAINABLETypes = () => {
    return [
      { id: "P", value: "Platinum" },
      { id: "G", value: "Gold" },
      { id: "S", value: "Silver" },
      { id: "B", value: "Bronze" },
      { id: "C", value: "Baseline" }
    ];
  };

  const fetchLEEDTypes = () => {
    return [
      { id: "P", value: "Platinum" },
      { id: "G", value: "Gold" },
      { id: "S", value: "Silver" },
      { id: "C", value: "Certified" },
      { id: "L", value: "CertifiedProject" },
      { id: "Z", value: "LeedZero" }
    ];
  };

  const fetchWIREDTypes = () => {
    return [
      { id: "P", value: "Platinum" },
      { id: "G", value: "Gold" },
      { id: "S", value: "Silver" },
      { id: "C", value: "Certified" }
    ];
  };

  const fetchGRESBTypes = () => {
    return [{ id: "G", value: "GRESB" }];
  };

  const fetchFITWELTypes = () => {
    return [{ id: "F", value: "FITWEL" }];
  };

  const fetchSMARTSCORETypes = () => {
    return [
      { id: "C", value: "Certified" },
      { id: "P", value: "Platinum" },
      { id: "G", value: "Gold" },
      { id: "S", value: "Silver" }
    ];
  };

  const fetchENERGYSTARTypes = () => {
    return [{ id: "E", value: "ENERGYSTAR" }];
  };

  const fetchZEROCARBONTypes = () => {
    return [{ id: "Z", value: "ZEROCARBON" }];
  };

  const fetchWELLTypes = () => {
    return [{ id: "W", value: "WELL" }];
  };

  const fetchBOMA360Types = () => {
    return [{ id: "T", value: "BOMA360" }];
  };

  const fetchRHFACTypes = () => {
    return [{ id: "R", value: "RHFAC" }];
  };

  const fetchWELL2023Types = () => {
    return [{ id: "X", value: "WELL2023" }];
  };

  const fetchBOMATOBYTypes = () => {
    return [{ id: "K", value: "BOMATOBY" }];
  };

  const fetchBOMAEXCELLENCETypes = () => {
    return [{ id: "M", value: "BOMAEXCELLENCE" }];
  };

  const fetchZonedTypes = () => {
    return [
      { id: "C", value: "Commercial" },
      { id: "I", value: "Industrial" },
      { id: "R", value: "Retail" },
      { id: "E", value: "Residential" },
      { id: "N", value: "Institutional" },
      { id: "O", value: "Other Leasable" }
    ];
  };

  const fetchSaleOrLease = () => {
    return [
      { id: "S", value: "Sale" },
      { id: "L", value: "Lease" }
    ];
  };

  const fetchLandTypes = () => {
    return [
      { id: "R", value: "Rural" },
      { id: "U", value: "Urban" },
      { id: "C", value: "Recreational" }
    ];
  };

  const fetchPropertySubTypes = () => {
    return [
      { id: "C", value: "Commercial" },
      { id: "R", value: "Recreational" },
      { id: "I", value: "Industrial" },
      { id: "E", value: "Retail" }
    ];
  };

  const fetchProposedUseTypes = () => {
    return [
      { id: "I", value: "Industrial" },
      { id: "O", value: "Office" },
      { id: "R", value: "Retail" },
      { id: "E", value: "Residential" },
      { id: "C", value: "Recreational" }
    ];
  };

  const fetchPermittedUseTypes = () => {
    return [
      { id: "R", value: "Retail" },
      { id: "I", value: "Industrial" },
      { id: "E", value: "Residential" },
      { id: "C", value: "Commercial" },
      { id: "U", value: "Rural" }
    ];
  };

  const fetchZoningTypes = () => {
    return [
      { id: "I", value: "Industrial" },
      { id: "O", value: "Office" },
      { id: "C", value: "Commercial" },
      { id: "R", value: "Residential" },
      { id: "A", value: "Agricultural" },
      { id: "M", value: "Mixed" }
    ];
  };

  const fetchFrontageTypes = () => {
    return [
      { id: "U", value: "County" },
      { id: "C", value: "City" },
      { id: "P", value: "Private" }
    ];
  };

  const fetchSurfacingTypes = () => {
    return [
      { id: "A", value: "Asphalt" },
      { id: "G", value: "Gravel" },
      { id: "C", value: "Concrete" }
    ];
  };

  const fetchWaterfrontTypes = () => {
    return [
      { id: "L", value: "Lake" },
      { id: "O", value: "Ocean" },
      { id: "R", value: "River" },
      { id: "P", value: "Pond" }
    ];
  };

  const fetchClassTypes = () => {
    return [
      { id: "3", value: "AAA" },
      { id: "A", value: "A" },
      { id: "B", value: "B" },
      { id: "C", value: "C" }
    ];
  };

  const fetchInteriorWallTypes = () => {
    return [
      { id: "D", value: "Dry Wall" },
      { id: "W", value: "Wood" },
      { id: "S", value: "Stone" },
      { id: "B", value: "Brick" },
      { id: "O", value: "Other" }
    ];
  };

  const fetchLeaseTerms = () => {
    return [
      { id: "F", value: "Flexible" },
      { id: "L", value: "Less Than One Year" },
      { id: "M", value: "Monthly" },
      { id: "Y", value: "Yearly" }
    ];
  };

  // fetchResidentialBuildingTypes = (
  //     ('C', 'Condominium'),
  //     ('L', 'Low-Rise'),
  //     ('M', 'Mid-Rise'),
  //     ('H', 'High-Rise'),
  //     ('T', 'Townhouse'),
  //     ('M', 'Mobile'),
  // )

  const fetchTenantRights = () => {
    return [
      {
        id: "right_of_first_refusal",
        value: "Right of First Refusal",
        code: "FR"
      },
      { id: "right_of_first_offer", value: "Right of First Offer", code: "FO" },
      { id: "right_of_relocation", value: "Right of Relocation", code: "RL" },
      { id: "right_of_signage", value: "Right of Signage", code: "SI" },
      {
        id: "right_of_early_termination",
        value: "Right of Early Termination",
        code: "ET"
      },
      { id: "right_of_audit", value: "Right of Audit", code: "AU" },
      { id: "right_of_exclusivity", value: "Right of Exclusivity", code: "EX" },
      { id: "right_of_contraction", value: "Right of Contraction", code: "CO" },
      { id: "right_of_expansion", value: "Right of Expansion", code: "EP" },
      { id: "right_of_renewal", value: "Right of Renewal", code: "RE" },
      { id: "right_of_gross_up", value: "Right of Gross Up", code: "GU" }
    ];
  };

  const noticePeriod = () => {
    return [
      { id: "N", value: "None" },
      { id: "3", value: "30 Days" },
      { id: "6", value: "60 Days" },
      { id: "9", value: "90 Days" },
      { id: "1", value: "120 Days" },
      { id: "8", value: "180 Days" }
    ];
  };

  const fetchMallTypes = () => {
    return [
      { id: "N", value: "Neighborhood Centre" },
      { id: "R", value: "Regional Centre" },
      { id: "S", value: "Super Regional Centre" },
      { id: "P", value: "Power Centre" },
      { id: "O", value: "Other" }
    ];
  };

  const fetchMeasurementUnits = () => {
    return [
      { id: "I", value: "Imperial" },
      { id: "M", value: "Metric" }
    ];
  };

  const fetchMediaTags = () => {
    return [
      { id: "T", value: "Thumbnail" },
      { id: "H", value: "Hero" },
      { id: "B", value: "Brochure" },
      { id: "3D", value: "3D" },
      { id: "FV", value: "Featured Video" },
      { id: "P", value: "Floor Plan" },
      { id: "SP", value: "Site Plan" }
    ];
  };

  const fetchSuiteVisibilityTypes = () => {
    return [
      { id: "PB", value: "Published" },
      { id: "PR", value: "Private" }
    ];
  };

  return {
    fetchBuildingTypes,
    fetchSuiteTypes,
    fetchSuiteAvailabilityTypes,
    fetchPubStatuses,
    fetchFavStatuses,
    fetchArchivedStatuses,
    fetchListingStatuses,
    fetchResidentialSuiteTypes,
    fetchSuiteConditionTypes,
    fetchResidentialBuildingTypes,
    fetchUserTypes,

    fetchExteriorWallTypes,
    fetchRoofingTypes,
    fetchBOMATypes,
    fetchBOMASUSTAINABLETypes,
    fetchLEEDTypes,
    fetchWIREDTypes,
    fetchGRESBTypes,
    fetchFITWELTypes,
    fetchSMARTSCORETypes,
    fetchENERGYSTARTypes,
    fetchZEROCARBONTypes,
    fetchWELLTypes,
    fetchBOMA360Types,
    fetchRHFACTypes,
    fetchWELL2023Types,
    fetchBOMATOBYTypes,
    fetchBOMAEXCELLENCETypes,
    fetchZonedTypes,
    fetchSaleOrLease,
    fetchLandTypes,
    fetchPropertySubTypes,
    fetchProposedUseTypes,
    fetchPermittedUseTypes,
    fetchZoningTypes,
    fetchFrontageTypes,
    fetchSurfacingTypes,
    fetchWaterfrontTypes,
    fetchClassTypes,
    fetchInteriorWallTypes,
    fetchLeaseTerms,

    fetchTenantRights,
    noticePeriod,
    fetchMallTypes,
    fetchMeasurementUnits,
    fetchMediaTags,
    fetchSuiteVisibilityTypes
  };
};

const api = LovService();
export default api;
