import * as lovConstants from "../constants/lovConstants";
import moment from "moment";

const currentYear = moment().year();

const initialState = {
  buildingTypes: [],
  suiteTypes: [],
  suiteAvailabilityTypes: [], // also called suite state types
  pubStatus: [],
  favStatus: [],
  archivedStatus: [],
  listingStatus: [],
  residentialSuiteTypes: [],
  suiteConditionTypes: [],
  residentialBuildingTypes: [],
  userTypes: [],
  exteriorWallTypes: [],
  roofingTypes: [],
  BOMATypes: [],
  LEEDTypes: [],
  WIREDTypes: [],
  FITWELTypes: [],
  GRESBTypes: [],
  SMARTSCORETypes: [],
  ENERGYSTARTypes: [],
  ZEROCARBONTypes: [],
  WELLTypes: [],
  BOMA360Types: [],
  RHFACTypes: [],
  WELL2023Types: [],
  BOMATOBYTypes: [],
  BOMAEXCELLENCETypes: [],
  BOMASUSTAINABLETypes: [],
  zonedTypes: [],
  saleOrLease: [],
  landTypes: [],
  propertySubTypes: [],
  proposedUseTypes: [],
  permittedUseTypes: [],
  zoningTypes: [],
  frontageTypes: [],
  surfacingTypes: [],
  waterfrontTypes: [],
  classTypes: [],
  interiorWallTypes: [],
  leaseTerms: [],
  noticePeriod: [],
  mallTypes: [],
  measurementUnits: [],
  tenantRights: [],

  colors: {
    "Mo-to-Mo": { background: "#794766", color: "#fff" },
    [currentYear]: { background: "#ac4164", color: "#fff" },
    [currentYear + 1]: { background: "#cc5262", color: "#fff" },
    [currentYear + 2]: { background: "#d16a42", color: "#fff" },
    [currentYear + 3]: { background: "#d7903e", color: "#fff" },
    [currentYear + 4]: { background: "#5e9d71", color: "#fff" },
    [currentYear + 5]: { background: "#458b8c", color: "#fff" },
    [currentYear + 6]: { background: "#4b5185", color: "#fff" },
    [currentYear + 7]: { background: "#674083", color: "#fff" },
    Vacant: { background: "#aeb1c4", color: "#fff" },
    Committed: { background: "#aeb1c4", color: "#fff" },
    Vacant_Marketing_Profile_Only: { background: "#ac39ac", color: "#fff" },
    LeasedOrCommitted_Marketing_Profile_Only: {
      background: "#fff",
      color: "#000000"
    }
  }
};

const reducer = function lovReducer(state = initialState, action) {
  switch (action.type) {
    case lovConstants.FETCH_ALL_LOV_TYPES_SUCCESSFUL:
      return Object.assign({}, state, action.payload);

    case lovConstants.FETCH_BUILDING_TYPES_SUCCESSFUL:
      return Object.assign({}, state, {
        buildingTypes: action.payload
      });

    case lovConstants.FETCH_SUITE_TYPES_SUCCESSFUL:
      return Object.assign({}, state, {
        suiteTypes: action.payload
      });

    case lovConstants.FETCH_PUB_STATUSES_SUCCESSFUL:
      return Object.assign({}, state, {
        pubStatus: action.payload
      });

    case lovConstants.FETCH_FAV_STATUSES_SUCCESSFUL:
      return Object.assign({}, state, {
        favStatus: action.payload
      });

    case lovConstants.FETCH_ARCHIVED_STATUSES_SUCCESSFUL:
      return Object.assign({}, state, {
        archivedStatus: action.payload
      });

    case lovConstants.FETCH_TENANT_RIGHTS_SUCCESSFUL:
      return Object.assign({}, state, {
        tenantRights: action.payload
      });

    default:
      return state;
  }
};

export default reducer;
